import React from 'react';
import ReactDOM from 'react-dom';
import Menu from './components/menu/main';
import OpenMenuBtn from './components/buttons/openMenu';
import { Iframe } from './components/Iframe';
import { CSSTransition } from 'react-transition-group';
import { getHashUrls } from './helpers/url';
import { defaultObjectId } from './constants/hud';

require('dotenv').config()

class Hud extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
      objectId: null,
    }
  }

  openMenu = () => {
    this.setState({
      menuOpen: true
    })
  }

  menuClose = () => {
    this.setState({
      menuOpen: false
    })
    this.terminalInterval()
  }

  getCookie = (name) => {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }

  setCookie = (name, value, days) => {
    var d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
  }

  terminalInterval = () => {
    this.interval = setInterval(() => {
      if (!window.location.href !== `${process.env.REACT_APP_SITE_URL}/#!p=936-yalta&lang=ru`) {
        this.setState({
          menuOpen: true,
        })
      }
    }, 300000);
  }
  componentDidMount = () => {
    let terminal = window.location.href.search("terminal");

    if (terminal !== -1) {
      this.setCookie("terminalCookie", 1)
    }

    let terminalCookie = this.getCookie("terminalCookie")


    if (terminalCookie == 1) {
      const body = document.querySelectorAll('body')[0];
      const stopInterval = () => {
        clearInterval(this.interval);
      }

      this.terminalInterval()

      body.addEventListener('mousemove', () => {
        stopInterval()
        if (!this.state.menuOpen) {
          this.terminalInterval()
        }
      });

      body.addEventListener('touchstart', () => {
        stopInterval()
        if (!this.state.menuOpen) {
          this.terminalInterval()
        }
      });
    }

    this.changeObjectId();
    this.checkTerminalFirstLoad();
  }

  checkTerminalFirstLoad = () => {
    const object = getHashUrls()["p"];
    const isTerminal = getHashUrls().hasOwnProperty('terminal');
    if (object === '992-yp-obzornaya' && isTerminal) {
      setTimeout(() => {
        this.openMenu();
      }, 5000);
    }
  }

  changeObjectId = (objectId = null , url = '') => {

    if (!objectId)
      objectId = getHashUrls()["p"] ? getHashUrls()["p"].split('-')[0] : defaultObjectId;

    if (url) 
      window.history.pushState(null, null, url);

    this.setState({
      objectId
    })
  }

  render() {
    const menuOpen = this.state.menuOpen;

    return (
      <>
        <Iframe
          objectId={this.state.objectId}
        />
        <CSSTransition
          in={!menuOpen}
          timeout={300}
          classNames="subcat"
          unmountOnExit
        >
          <OpenMenuBtn openMenu={this.openMenu} />
        </CSSTransition>
        <CSSTransition
          in={menuOpen}
          timeout={50}
          classNames="menu-transition"
          unmountOnExit
        >
          <Menu
            menuOpen={this.state.menuOpen}
            menuClose={this.menuClose}
            changeObjectId={this.changeObjectId}
          />
        </CSSTransition>
      </>
    );
  }
}

// ========================================

ReactDOM.render(<Hud />, document.getElementById("root-main-menu"));
