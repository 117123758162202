import React from 'react';

class smallLoader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            <svg className="object-loads" id="smallLoader" width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" ><g transform="translate(20 50)">\
                <circle cx="0" cy="0" r="6" fill="#ffffff" transform="scale(0.572192 0.572192)">\
                    <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>\
                </circle>\
                </g><g transform="translate(40 50)">\
                <circle cx="0" cy="0" r="6" fill="#ffffff" transform="scale(0.224255 0.224255)">\
                    <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>\
                </circle>\
                </g><g transform="translate(60 50)">\
                <circle cx="0" cy="0" r="6" fill="#ffffff" transform="scale(0.00676722 0.00676722)">\
                    <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>\
                </circle>\
                </g><g transform="translate(80 50)">\
                <circle cx="0" cy="0" r="6" fill="#ffffff" transform="scale(0.115136 0.115136)">\
                    <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>\
                </circle>\
                </g>
            </svg>
        )
    }
}


export default smallLoader;

