import React from 'react';

class CloseBtn extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div onClick={this.props.menuClose} className="main-panel__close-btn">
                <svg version="1.1" id="Layer_2" x="0px" y="0px"
                    viewBox="0 0 128 128">
                    <g>
                        <path className="" d="M9.1,122.9c-1,0-2-0.4-2.8-1.2c-1.6-1.6-1.6-4.1,0-5.7L116,6.3c1.6-1.6,4.1-1.6,5.7,0c1.6,1.6,1.6,4.1,0,5.7
		L12,121.7C11.2,122.5,10.2,122.9,9.1,122.9z"/>
                    </g>
                    <g>
                        <path className="" d="M53.5,58c-1,0-2-0.4-2.8-1.2L5.9,12.1c-1.6-1.6-1.6-4.1,0-5.7c1.6-1.6,4.1-1.6,5.7,0l44.8,44.8
		c1.6,1.6,1.6,4.1,0,5.7C55.6,57.6,54.5,58,53.5,58z"/>
                    </g>
                    <g>
                        <path className="" d="M118.7,123.2c-1,0-2-0.4-2.8-1.2L71.9,78.1c-1.6-1.6-1.6-4.1,0-5.7c1.6-1.6,4.1-1.6,5.7,0l43.9,43.9
		c1.6,1.6,1.6,4.1,0,5.7C120.7,122.8,119.7,123.2,118.7,123.2z"/>
                    </g>
                </svg>
            </div>
        )
    }
}

export default CloseBtn;