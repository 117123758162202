import React, { useState } from "react";
import InputRange from "../../controls/InputRange";
import { getNewFilterObject } from "./helperFilter";
import Dropdown from "../../controls/Dropdown";

import './style.css';

const categories = [
    {
        value: 998,
        label: 'Квартиры',
    },
    {
        value: 937,
        label: 'Апартаменты',
    },
    {
        value: 938,
        label: 'Дома',
    },
    {
        value: 939,
        label: 'Участки',
    },
    {
        value: 941,
        label: 'Новостройки',
    },
    {
        value: 940,
        label: 'Коммерческая недвижимость',
    },
];

const defaultFilter = {
    square: [1, 3000],
    price: [1, 1000000],
}

const FilterBody = (props) => {
    const [filter, setFilter] = useState(defaultFilter);

    const changeFilter = (payload) => {
        const oldFilter = { ...filter };
        const newFilter = getNewFilterObject(oldFilter, payload);

        setFilter(newFilter);
    }

    const onCloseBtn = () => {
        setFilter(defaultFilter);
        props.changeScreen('main');
        props.getTopObjects();
    }

    return (
        <div className="filter">
            <div className="filter-field">
                <Dropdown
                    options={categories}
                    name="parents"
                    returnPayload={(payload) => changeFilter(payload)}
                />
            </div>
            <div className="filter-field filter-field_range">
                <InputRange
                    name="area"
                    label="Площадь"
                    returnPayload={(payload) => changeFilter(payload)}
                    max={defaultFilter.square[1]}
                    unit="м2"
                />
            </div>
            <div className="filter-field filter-field_range">
                <InputRange
                    name="price"
                    label="Цена"
                    returnPayload={(payload) => changeFilter(payload)}
                    max={defaultFilter.price[1]}
                    unit="€"
                />
            </div>

            <div className='filter__buttons-panel'>
                <div onClick={() => props.applyFilter(filter)} className="filter__btn filter-btn_apply">
                    Поиск
                </div>
                <div onClick={() => onCloseBtn()} className="filter__btn">
                    Очистить
                </div>
            </div>
        </div>
    )
}

export default FilterBody;