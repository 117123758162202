import React from 'react';

const FilterBtn = (props) => {
    return (
        <div className='filter-btn' onClick={() => props.changeScreen('filter')}>
            Филтър
        </div>
    )
} 

export default FilterBtn;