import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { changeLocationName } from '../../service/changeLocationName'
import './style.css'

export const Iframe = (props) => {

    const [panoUrl, setPanoUrl] = useState('');

    useEffect(() => {
        getPano();
    });

    const getPano = () => {
        axios.get(`${process.env.REACT_APP_SITE_URL}/api/get-object?cultureKey=ru&lang=ru&itemId=${props.objectId}`)
            .then(res => {
                setPanoUrl(res.data.panoUrl);
                changeLocationName(res.data.location, res.data.longtitle, res.data.custom_id_object);
            })
    }

    return (
        <>
            <iframe class="main-pano" src={panoUrl}></iframe>
        </>
    )
};
