import React from 'react';
import '../../css/menu.css';
import TopPanel from './../top/panel';
import BodyPanel from './../body/panel';
import axios from 'axios';
import BackBtn from "./../buttons/back"
import CloceBtn from "./../buttons/close"
import { GetHashUrls } from '../../helpers/getHashUrls';
import FilterBody from '../body/filter-body/FIlterBody';

export default class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            objectsCategory: "all",
            filter: "",
            isLoad: false,
            objects: "",
            offset: "10",
            loadMore: false,
            allObjectsLoad: false,
            onSearch: false,
            currentParent: "",
            beaches: false,
            food: false,
            live: false,
            realEstate: false,
            religious: false,
            sights: false,
            touristRoutes: false,
            shops: false,
            business: false,
            screen: 'main',
            filterRealEstate: ''
        };
    }

    componentDidMount() {
        this.GetTopObjects()
        const bodyScroll = document.querySelectorAll(".main-panel__search-container")[0]
        bodyScroll.addEventListener('scroll', this.handleScroll);
    }

    GetHashUrls = () => {
        let vars = [],
            hash;
        let hashes = window.location.href.slice(window.location.href.indexOf('#') + 2).split('&');
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    }

    GetTopObjects = () => {
        const excludeObjects = this.getExcludeObjects()
        const objectIdCat = GetHashUrls()["p"];
        let ObjectId;
        if (objectIdCat) {
            ObjectId = parseInt(objectIdCat.match(/\d+/))
        }
        let itsParent = false;

        axios.get(`${process.env.REACT_APP_SITE_URL}api/get-object?cultureKey=ru&itemId=${ObjectId}&lang=ru`)
            .then(res => {
                itsParent = res.data.parent
                if (this.state.objectsCategory != "all") {
                    this.setState({
                        objectsCategory: "all",
                        objects: [],
                        isLoad: false,
                    })
                }

                if (itsParent) {
                    axios.get(`${process.env.REACT_APP_SITE_URL}/api/get-parent-objects?lang=ru&parents=${objectIdCat}`)
                        .then(res => {
                            this.setState({
                                objects: [...this.state.objects, ...res.data.items],
                                isLoad: true,
                                loadMore: false
                            }, () => {
                                const bodyScroll = document.querySelectorAll(".main-panel__search-container")[0]
                                if (bodyScroll)
                                    bodyScroll.addEventListener('scroll', this.handleScroll);
                            })
                        })
                }
                else {
                    axios.get(`${process.env.REACT_APP_SITE_URL}/api/get-top-objects?lang=ru&rand=0.012310746761863944&exclude=${excludeObjects}`)
                        .then(res => {
                            if (res.data.items) {
                                this.setState({
                                    objects: [...this.state.objects, ...res.data.items],
                                    isLoad: true,
                                    loadMore: false
                                }, () => {
                                    const bodyScroll = document.querySelectorAll(".main-panel__search-container")[0]
                                    if (bodyScroll)
                                        bodyScroll.addEventListener('scroll', this.handleScroll);
                                })
                            }
                        })
                }
            })
    }

    changeCat = (parrentCategory) => {
        const ObjCategories = {
            c41: "realEstate",
            c47: "food",
            c40: "sights",
            c66: 'religious',
            c39: 'beaches',
            c131: 'touristRoutes',
            c71: 'live',
            c75: 'shops',
            c225: 'business'
        }
        const oldParrent = ObjCategories[this.state.currentParent]
        const newParrent = "c" + parrentCategory;
        const newParrentName = ObjCategories[newParrent]
        if (oldParrent != newParrentName) {
            this.setState({
                currentParent: newParrent,
                [newParrentName]: true,
                [oldParrent]: false
            })
        }

    }

    changeObjects = (parent, categoryID, parrentCategory) => {
        if (this.state.isLoad) {
            if (this.state.allObjectsLoad) {
                const bodyScroll = document.querySelectorAll(".main-panel__search-container")[0]
                bodyScroll.addEventListener('scroll', this.handleScroll);
            }
            this.setState({ isLoad: false })
            const currentParent = "?parents=" + parent;
            const currentCategory = "&categoryId=" + categoryID;
            const language = "ru";
            const apiUrl = `${process.env.REACT_APP_SITE_URL}/api/objects` + currentParent + currentCategory + "&lang=" + language;
            this.setState({
                objectsCategory: currentCategory,
            })
            this.changeCat(parrentCategory)
            axios.get(apiUrl)
                .then(res => {
                    this.setState({
                        objects: res.data.items,
                        isLoad: true,
                        offset: "10",
                        allObjectsLoad: false
                    }, () => {
                        const bodyScroll = document.querySelectorAll(".main-panel__search-container")[0]
                        bodyScroll.addEventListener('scroll', this.handleScroll);
                    })
                })
        }
    }

    changeObjectsEstate = (parent, categoryID, parrentCategory, categoryFilter) => {
        if (this.state.isLoad) {
            this.setState({
                isLoad: false,
            }, () => {
                const currentParent = "?parents=" + parent;
                const currentCategory = "&categoryId=" + categoryID;
                const language = "ru";
                const apiUrl = `${process.env.REACT_APP_SITE_URL}/api/get-real-estate-dev` + currentParent + currentCategory + "&filter=" + categoryFilter + "&lang=" + language + "&currency=thb";

                this.setState({ objectsCategory: currentCategory })
                this.changeCat(parrentCategory)
                axios.get(apiUrl)
                    .then(res => {
                        this.setState({
                            objects: res.data.items,
                            filter: categoryFilter,
                            isLoad: true,
                            offset: "10",
                            allObjectsLoad: false
                        }, () => {
                        })
                    })
            })
        }
    }

    liveSearch = (title) => {
        if (this.state.isLoad) {
            this.setState({
                onSearch: true,
                isLoad: false,
                offset: "10",
                objectsCategory: "search",
                titleSearch: title
            })
            axios.get(`${process.env.REACT_APP_SITE_URL}/api/ajax-search?lang=ru&title=${title}&offset=0&exclude=0&categoryId=0&currency=thb&rand=0.2654048968404197&currency=thb`)
                .then(res => {
                    if (res) {
                        this.setState({
                            objects: res.data,
                            isLoad: true
                        })
                    }
                })
        }
    }

    handleScroll = (e) => {
        if (e.target.scrollTop + 1200 > e.target.scrollHeight && !this.state.loadMore && !this.state.allObjectsLoad) {
            const bodyScroll = document.querySelectorAll(".main-panel__search-container")[0]
            bodyScroll.removeEventListener('scroll', this.handleScroll);
            this.setState({
                loadMore: true,
            }, () => {
                if (this.state.objectsCategory === "search") {
                    this.loadMoreSearch()
                }
                else {
                    this.loadMore()
                }
            })
        }
    }

    getExcludeObjects = () => {
        let excludeObjects = [];
        excludeObjects = Array.from(this.state.objects).map(function (object) {
            return object.id;
        });
        return excludeObjects
    }

    loadMoreSearch = () => {
        this.setState({
            onSearch: true,
            loadMore: true,
            objectsCategory: "search",
        })
        const excludeObjects = this.getExcludeObjects();
        axios.get(`${process.env.REACT_APP_SITE_URL}/api/ajax-search?lang=ru&title=${this.state.titleSearch}&exclude=${excludeObjects}&categoryId=0&currency=thb&rand=0.2654048968404197&currency=thb`)
            .then(res => {
                if (res.data.length) {
                    this.setState({
                        objects: [...this.state.objects, ...res.data],
                        offset: +this.state.offset + 10,
                        loadMore: false
                    }, () => {
                        const bodyScroll = document.querySelectorAll(".main-panel__search-container")[0]
                        bodyScroll.addEventListener('scroll', this.handleScroll);
                    })
                }
                else {
                    this.setState({
                        allObjectsLoad: true
                    })
                }
            })
    }

    changeScreen = (screenName) => {
        this.setState({
            screen: screenName
        })
    }

    applyFilter = (filter) => {
        let filterString = '';
        console.log(filter)

        for (let key in filter) {

            if (key === 'price' || key === 'area') {
                filterString = filterString + '&' + `${key}1` + '=' + filter[key][0];
                filterString = filterString + '&' + `${key}2` + '=' + filter[key][1];
                
            } else {
                filterString = filterString + '&' + key + '=' + filter[key];
            }
        }

        if (this.state.isLoad) {
            if (this.state.allObjectsLoad) {
                const bodyScroll = document.querySelectorAll(".main-panel__search-container")[0]
                bodyScroll.addEventListener('scroll', this.handleScroll);
            }
            this.setState({ isLoad: false })

            const language = "ru";
            const apiUrl = `${process.env.REACT_APP_SITE_URL}/api/objects&lang=${language}` + filterString;

            axios.get(apiUrl)
                .then(res => {
                    this.setState({
                        objects: res.data.items,
                        isLoad: true,
                        offset: "10",
                        allObjectsLoad: false
                    }, () => {
                        const bodyScroll = document.querySelectorAll(".main-panel__search-container")[0]
                        if (bodyScroll)
                            bodyScroll.addEventListener('scroll', this.handleScroll);
                    })
                })
        }

        this.setState({
            filter: filterString,
            screen: 'main'
        })
    }

    loadMore = () => {
        this.setState({
            loadMore: true,
        }, () => {
            const currentCategory = parseInt(this.state.objectsCategory.match(/\d+/));
            const offset = this.state.offset;
            const categoryFilter = this.state.filter
            if (this.state.objectsCategory == "all") {
                this.GetTopObjects()
            }
            else {
                axios.get(`${process.env.REACT_APP_SITE_URL}/api/loading-objects?category=${currentCategory}&lang=ru&offset=${offset}&categoryId=0&currency=thb&filter=${categoryFilter}`)
                    .then(res => {
                        if (res.data.items.length) {
                            this.setState({
                                objects: [...this.state.objects, ...res.data.items],
                                isLoad: true,
                                offset: +offset + 5,
                                loadMore: false
                            }, () => {
                                const bodyScroll = document.querySelectorAll(".main-panel__search-container")[0]
                                bodyScroll.addEventListener('scroll', this.handleScroll);
                            })
                        }
                        else {
                            this.setState({
                                allObjectsLoad: true,
                                loadMore: false
                            })
                        }
                    })
            }
        })
    }

    render() {
        if (this.state.screen === 'main') {
            return (
                <div className="main-panel">
                    <div className="main-panel__wrap">
                        <TopPanel
                            handleChange={this.handleChange}
                            liveSearch={this.liveSearch}
                        />
                        <BodyPanel
                            menuClose={this.props.menuClose}
                            onScroll={this.handleScroll}
                            objects={this.state.objects}
                            changeObjects={this.changeObjects}
                            isLoad={this.state.isLoad}
                            changeObjectsEstate={this.changeObjectsEstate}
                            loadMore={this.state.loadMore}
                            allObjectsLoad={this.state.allObjectsLoad}
                            onSearch={this.state.onSearch}
                            sights={this.state.sights}
                            food={this.state.food}
                            realEstate={this.state.realEstate}
                            shops={this.state.shops}
                            religious={this.state.religious}
                            business={this.state.business}
                            changeScreen={this.changeScreen}
                            changeObjectId={this.props.changeObjectId}
                        />
                        <div className="main-panel__bottom">
                            {this.state.isLoad && this.state.objectsCategory != "all" ?
                                <BackBtn GetTopObjects={this.GetTopObjects} />
                                :
                                ""
                            } {this.state.isLoad ?
                                <CloceBtn
                                    menuClose={this.props.menuClose}
                                />
                                :
                                ""
                            }
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="main-panel">
                <div className="main-panel__wrap">
                    <FilterBody
                        getTopObjects={this.GetTopObjects}
                        applyFilter={this.applyFilter}
                        changeScreen={this.changeScreen}
                    />
                </div>
            </div>
        )

    }
}