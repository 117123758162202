import React from 'react';
import ObjectEstate from '../object/estate'


class ObjectPano extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  handlerClickObject(objectId, url) {
    this.props.changeObjectId(objectId, url);
    this.props.menuClose();
  }

  render() {
    const object = this.props.object;
    const objectId = object.id ? object.id : object.itemId;
    const objectAlias = object.alias ? object.alias : object.alias;
    const panoId = object.panoId;
    const url = `${process.env.REACT_APP_SITE_URL}/#!p=${objectId}-${objectAlias}&s=pano${panoId}&lang=ru`;

    if ((object.type != 0 && object.type != undefined) || (object.typeRealEstate == 2) || (object.typeRealEstate == 1)) {
      return (
        <div onClick={() => { this.handlerClickObject(objectId, url)}} href={url}>
          <ObjectEstate
            object={object}
          />
        </div>
      )
    }
    if (object.img) {
      return (
        <div onClick={() => { this.handlerClickObject(objectId, url)}} href={url}>
          <div style={{ backgroundImage: `url(${process.env.REACT_APP_SITE_URL}${object.img})` }} className="main-panel__search-item">
            <div className="main-panel__item-title"><span className="title-district">{object.longtitle}</span>{object.districtTitle}</div>
            <div className="object-line object-line_bottom"></div>
          </div>
        </div>
      )
    }
    if (object.image) {
      return (
        <div onClick={() => { this.handlerClickObject(objectId, url)}} href={url}>
          <div style={{ backgroundImage: `url(${process.env.REACT_APP_SITE_URL}${object.image})` }} className="main-panel__search-item">
            <div dangerouslySetInnerHTML={{ __html: object.titleWithLocation }} className="main-panel__item-title">
            </div>
            <div className="object-line object-line_bottom"></div>
          </div>
        </div>
      )
    }
    else {
      return (
        <></>
      )
    }
  }
}

export default ObjectPano;