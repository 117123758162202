import React from "react";
import { Range } from 'react-range';

const STEP = 5;
const MIN = 0;

const InputRange = ({ name, label, returnPayload, min = 1, max, unit}) => {
    const [values, setValues] = React.useState([min, max]);

    const onChange = (values) => {
        setValues(values)
        returnPayload({ [name]: values })
    }

    return (
        <div>
            <label htmlFor={name}>{label}</label>
            <div className="range-input">
                <Range
                    name={name}
                    values={values}
                    step={STEP}
                    min={MIN}
                    max={max}
                    onChange={(values) => onChange(values)}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: '2px',
                                display: 'flex',
                                width: '100%',
                                backgroundColor: '#fff',
                            }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    height: '5px',
                                    width: '100%',
                                    borderRadius: '4px',
                                    alignSelf: 'center'
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '15px',
                                width: '15px',
                                borderRadius: '50%',
                                backgroundColor: '#FFF',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'red',
                            }}
                        >
                            <div
                                style={{
                                    height: '5px',
                                    width: '5px',
                                    borderRadius: '50%',
                                    backgroundColor: isDragged ? '#fff' : '#fff'
                                }}
                            />
                        </div>
                    )}
                />
                <output id="output">
                    {values[0]} - {values[1]} {unit}
                </output>
            </div>
        </div>

    )
}

export default InputRange;