import React, {useState} from "react";

const Dropdown = ({
    options,
    name,
    returnPayload
  }) => {
    const onChange = (value) => {
      console.log(value)
      setSelectedOption(value)
      returnPayload({ [name]: value })
  }

    const [selectedOption, setSelectedOption] = useState(options[0].value);
    return (
        <select
          value={selectedOption}
          onChange={e => onChange(e.target.value)}>
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
    );
  };

  export default Dropdown