import React from 'react';

class TopPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
    };
    this.timeout = 0;
  }

  handleChange = (e) => {
    this.setState({ searchInput: e.target.value });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.liveSearch(e.target.value)
    }, 300);
  }

  render() {
    return (
      <div className="main-panel__top">
        {/*<div className="main-panel__menu-btn">

        </div>
        */
        }
        <input onChange={this.handleChange} value={this.state.searchInput} placeholder="Поиск" className="main-panel__top-search" type="text"></input>
        <a className="main-panel__top-logo" href="/">

        </a>

      </div>
    )
  }
}

export default TopPanel;