import React from 'react';

class OpenMenu extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <a onClick={this.props.openMenu} className="category-search-btn" id="btn-new-menu">
                <span className="category-search-btn-text">Меню</span>
                <svg className="category-search-btn-img" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 128 128">
                    <g id="Podlojki">
                        <g>
                            <path className="st0" d="M123.8,127.8H4.2c-2.2,0-4-1.8-4-4V4.2c0-2.2,1.8-4,4-4h119.7c2.2,0,4,1.8,4,4v119.7    C127.8,126,126,127.8,123.8,127.8z M8.2,119.8h111.7V8.2H8.2V119.8z" />
                        </g>
                        <g>
                            <path className="st0" d="M104,38H23.7c-2.2,0-4-1.8-4-4s1.8-4,4-4H104c2.2,0,4,1.8,4,4S106.2,38,104,38z" />
                        </g>
                        <g>
                            <path className="st0" d="M104,68H23.7c-2.2,0-4-1.8-4-4s1.8-4,4-4H104c2.2,0,4,1.8,4,4S106.2,68,104,68z" />
                        </g>
                        <g>
                            <path className="st0" d="M104,98H23.7c-2.2,0-4-1.8-4-4s1.8-4,4-4H104c2.2,0,4,1.8,4,4S106.2,98,104,98z" />
                        </g>
                    </g>

                </svg>
                <div className="animation-border red"></div>
                <div className="btn-animation red"></div>
                <div className="animation-background red"></div>
            </a >
        )
    }
}

export default OpenMenu;