import React from 'react';
import Subcat from '../elements/subcat'
import axios from 'axios';

import { CSSTransition } from 'react-transition-group';

class CategoryFood extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            subcategories: []
        }
    }

    onClick = (e) => {
        // ym('reachGoal', 'food');
        this.props.changeObjects(this.props.itemId, this.props.searchId, this.props.searchId, e)

        this.props.changeParentCat(this.props.itemId)
        axios.get(`${process.env.REACT_APP_SITE_URL}/api/categories?&categoryId=${this.props.searchId}&lang=ru`)
            .then(res => {
                this.setState({
                    subcategories: res.data.items
                })
            })
    }

    render() {
        const subcats = this.state.subcategories;
        const panoUrl = this.props.panourl;
        const parentId = this.props.searchId;
        const activeParentCat = this.props.activeParentCat;
        const itemId = this.props.itemId;
        const borderColor = this.props.borderColor;

        return (
            <div className="main-panel__cat-item">
                <a href={panoUrl ? panoUrl : '#'} onClick={(e) => this.onClick(e)} className="cat-icon">
                    <svg fill={borderColor} className="cat-icon__svg" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        y="0px" viewBox="0 0 60 60"
                    >
                        <g>
                            <path
                                d="M30,59.8C13.5,59.8,0.2,46.5,0.2,30S13.5,0.3,30,0.3S59.8,13.6,59.8,30S46.4,59.8,30,59.8z M30,2.3											  C14.6,2.3,2.2,14.7,2.2,30c0,15.3,12.5,27.8,27.8,27.8S57.8,45.4,57.8,30C57.8,14.7,45.3,2.3,30,2.3z">
                            </path>
                        </g>
                        <g>
                            <path d="M30,58.8H1.2V30c0,0-0.4,11.7,8.3,20.3S30,58.8,30,58.8z"></path>
                            <path
                                d="M30,59.8H0.2V30.6c0-0.3,0-0.5,0-0.6l2,0v0.4c0,1.9,0.6,11.8,8,19.2c8.3,8.2,19.6,8.3,19.8,8.3V59.8z											   M2.2,57.8h17.6c-3.5-1.3-7.5-3.4-11-6.8c-3.3-3.3-5.4-6.9-6.6-10.3V57.8z">
                            </path>
                        </g>
                    </svg>
                    <div className="cat-icon__img">
                        <img src={`${process.env.REACT_APP_SITE_URL}` + this.props.icon} alt="" />
                    </div>
                </a>
                <div className="main-panel__cat-title">
                    {this.props.title}
                </div>
                <CSSTransition in={activeParentCat == itemId}
                    classNames="subcat"
                    timeout={300}
                    unmountOnExit
                >

                    <div className="main-panel__subcat">
                        {Object.keys(subcats).map((subcat, i) => <Subcat
                            goal={subcats[i].metrika}
                            changeObjects={this.props.changeObjects}
                            changeActiveCat={this.props.changeActiveCat}
                            title={subcats[i].longtitle}
                            idCategory={subcats[i].searchId}
                            id={subcats[i].itemId}
                            parent={parentId}
                            activeCat={this.props.activeCat}
                            key={i}
                            pano_url={subcats[i].site_tour_url}
                        />
                        )}
                    </div>
                </CSSTransition>
            </div>
        )
    }
}

export default CategoryFood;