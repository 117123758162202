import React from 'react';

class ObjectEstate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const object = this.props.object;
        const urlImg = object.squareImg ? object.squareImg : object.square_img;
        const title = object.titleWithLocation ? object.titleWithLocation : object.longtitle;
        const objectId = object.custom_id_object;
        const currency = object.currency ? object.currency : 'лв';

        return (
            <div className="main-panel__search-item estate">
                <div className="object-line object-line_top"></div>
                <div style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 6%, rgba(0, 0, 0, 0) 87%, rgba(0, 0, 0, 1) 110%), url(${process.env.REACT_APP_SITE_URL}${urlImg})` }} className="main-panel__item-img">
                    <div className="main-panel__item-id">
                        {objectId ? `ID: ${objectId}` : null}
                    </div>
                    {object.type == 2 &&
                        <div className="main-panel__item-sale">
                        </div>
                    }
                    {object.type == 1 &&
                        <div className="main-panel__item-rent">
                        </div>
                    }
                </div>
                <div className="main-panel__item-container">
                    <div className="main-panel__item-loc-wrap">
                        <div dangerouslySetInnerHTML={{ __html: title }} className="main-panel__item-title">
                        </div>
                    </div>
                    <div className="main-panel__item-cat">
                        {object.category}
                    </div>
                    {object.bedrooms &&
                        <div className="main-panel__item-bedrooms">

                            <span>
                                {object.bedrooms}
                            </span>

                            <div className="main-panel__bedrooms-img">
                            </div>
                        </div>
                    }
                    <div className="main-panel__item-area">
                        {object.area &&
                            <span>
                                {object.area} кв. м.
                            </span>
                        }
                    </div>
                    <div className="main-panel__item-price">
                        {object.priceSale != 0 && !object.sold &&
                            < span >
                                {(String(object.priceSale)).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')} <span className="price">{currency}</span>
                            </span>
                        }
                    </div>
                    {object.sold &&
                        <div className="main-panel__item-price">
                            Продано
                        </div>
                    }
                    <div>
                    </div>
                </div>
                <div className="object-line object-line_bottom"></div>
            </div>
        )
    }
}

export default ObjectEstate;