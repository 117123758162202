import React from 'react';

class BackBtn extends React.Component {
    constructor(props) {
        super(props)
    }
    
    render() {
        return (
            <div onClick={this.props.GetTopObjects} className="main-panel__back-btn">
                <svg version="1.1" x="0px" y="0px" viewBox="0 0 128 128" >
                    <g id="Podlojki">
                    </g>
                    <g id="Layer_2">
                        <path className="" d="M87.3,122.3H4.5c-2.2,0-4-1.8-4-4s1.8-4,4-4h82.8c17.8,0,32.2-14.5,32.2-32.2c0-17.8-14.5-32.2-32.2-32.2H4.5
		c-1.6,0-3-0.9-3.6-2.3c-0.7-1.4-0.4-3.1,0.6-4.3L32.9,7.1c1.4-1.7,4-1.9,5.6-0.4c1.7,1.4,1.9,4,0.4,5.6L13.3,41.9h74
		c22.2,0,40.2,18,40.2,40.2C127.5,104.3,109.5,122.3,87.3,122.3z"/>
                    </g>
                </svg>
            </div>
        )
    }
}

export default BackBtn;