import React from 'react';
import axios from 'axios';
import Category from '../category/category';
import FilterBtn from '../buttons/FilterBtn';

class SidebarList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeCat: "",
            parentCategories: []
        };
    }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_SITE_URL}/api/categories?parents=4-categories-estate&categoryId=4&lang=ru&pagetitle=&rand=0.7143301590562461`)
            .then(res => {
                this.setState({
                    parentCategories: res.data.items
                })
            })
    }

    changeActiveCat = (categoryID) => {
        this.setState({
            activeCat: categoryID
        })
    }

    changeParentCat = (categoryID) => {
        this.setState({
            activeParentCat: categoryID
        })
    }

    render() {
        const parentCategories = this.state.parentCategories;

        return (
            <div className="main-panel__categories">
                <FilterBtn
                    changeScreen={this.props.changeScreen}
                />
                <>
                    {parentCategories.map((cat, i) => {
                        if (cat.itemId != 41) {
                            return <Category
                                title={cat.title}
                                searchId={cat.searchId}
                                itemId={cat.itemId}
                                icon={cat.icon}
                                borderColor={cat.borderColor}
                                key={i}
                                changeObjects={this.props.changeObjects}
                                activeCat={this.state.activeCat}
                                changeActiveCat={this.changeActiveCat}
                                activeParentCat={this.state.activeParentCat}
                                changeParentCat={this.changeParentCat}
                                panourl={cat.site_tour_url}
                            />
                        }
                    })}
                </>
            </div>
        )
    }
}
export default SidebarList;