import React from 'react';
import ym from 'react-yandex-metrika';


class Subcat extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    onClick = (idCategory, categoryID, parrentCategory, categoryFilter, e) => {
        if (this.props.goal) {
            ym('reachGoal', this.props.goal);
        }

        this.props.changeObjects(idCategory, categoryID, parrentCategory, categoryFilter, e)
        this.props.changeActiveCat(categoryID)
    }

    render() {
        const panoUrl = this.props.pano_url


        const idCategory = this.props.idCategory
        const id = this.props.id
        const parent = this.props.parent
        const activeClass = this.props.activeCat == id ? "main-panel__subcat-title active" : "main-panel__subcat-title"
        const categoryFilter = this.props.categoryFilter


        if (panoUrl) {
            return (
                <a className={activeClass} href={panoUrl}>
                    <div onClick={(e) => this.onClick(idCategory, id, parent, categoryFilter, e)} >
                        {this.props.title}
                    </div >
                </a>
            )
        }
        else {
            return (
                <div onClick={(e) => this.onClick(idCategory, id, parent, categoryFilter, e)} className={activeClass}>
                    {this.props.title}
                </div >
            )
        }
    }
}
export default Subcat;