import React from 'react';
import ObjectPano from '../object/pano'
import SidebarList from '../sidebar/list'
import Preloader from '../img/preloader'
import SmallLoader from "../img/smallPreloader"

class BodyPanel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const isLoad = this.props.isLoad;

    if (isLoad) {
      const objects = this.props.objects;

      return (
        <div className="main-panel__search-wrap">
          <div className="main-panel__search-container">
            {Object.keys(objects).map((object, i) => <ObjectPano
              menuClose={this.props.menuClose}
              object={objects[i]}
              key={i}
              onSearch={this.props.onSearch}
              changeObjectId={this.props.changeObjectId}
            />)}
            {!objects.length ?
              <div className="main-panel__search-text">Ничего не найдено</div>
              :
              ""
            }
            {this.props.loadMore && !this.props.allObjectsLoad ?
              <SmallLoader />
              :
              ""
            }
          </div>
          <SidebarList
            changeScreen={this.props.changeScreen}
            changeObjectsEstate={this.props.changeObjectsEstate}
            changeObjects={this.props.changeObjects}
            sights={this.props.sights}
            food={this.props.food}
            realEstate={this.props.realEstate}
            shops={this.props.shops}
            religious={this.props.religious}
            business={this.props.business}
          />
        </div >
      )
    }
    else {
      return (
        <div className="main-panel__search-wrap">
          <div className="main-panel__search-container">
            <Preloader />
          </div>
          <SidebarList
            changeObjectsEstate={this.props.changeObjectsEstate}
            changeObjects={this.props.changeObjects}
            sights={this.props.sights}
            food={this.props.food}
            realEstate={this.props.realEstate}
            shops={this.props.shops}
            religious={this.props.religious}
            business={this.props.business}
            changeScreen={this.props.changeScreen}
          />
        </div>
      )
    }
  }
}

export default BodyPanel;